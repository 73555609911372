<template>
<div class="selfcheck-container" id="js_selfcheck_container">
	<article class="selfcheck temperaturedifference">
		<h1 class="selfcheck__header"><img src="~@/assets/img/selfcheck-temperature-difference/title01.png" alt="医師監修 寒暖差疲労セルフチェック presented by 頭痛ーる"></h1>

		<!-- 紹介 -->
		<section class="selfcheck__intro">
			季節の変わり目になるといつも体調が悪くなる、急な寒さや暑さに疲れを感じる…そんな症状がある人は「寒暖差疲労」かもしれません。日常生活を振り返り、以下の質問に答えて確認してみましょう。（質問は10問あります。）<br>
			<span class="is-small">監修:せたがや内科・神経内科クリニック 久手堅 司先生</span>
		</section>
		<!-- /紹介 -->

		<!-- NOTE:ここから公式WEBに流用 -->
		<!-- 寒暖差疲労セルフチェック -->
		<section class="selfcheck__questions">
			<ul class="selfcheck__questions--list">
				<li class="selfcheck__questions--item">
					<span class="selfcheck__questions--heading">Q.1</span>
					<p class="selfcheck__questions--text">暑さ、寒さが苦手である</p>
					<div class="selfcheck__questions--label">
						<input type="radio" name="q01" :value="1" v-model="question[0]" id="selfcheck__q01--yes">
						<label for="selfcheck__q01--yes"><img src="~@/assets/img/shindan_ok.svg" alt="YES"></label>
						<input type="radio" name="q01" :value="0" v-model="question[0]" id="selfcheck__q01--no">
						<label for="selfcheck__q01--no"><img src="~@/assets/img/shindan_ng.svg" alt="NO"></label>
					</div>
				</li>
				<li class="selfcheck__questions--item">
					<span class="selfcheck__questions--heading">Q.2</span>
					<p class="selfcheck__questions--text">エアコン（冷房、暖房）が苦手</p>
					<div class="selfcheck__questions--label">
						<input type="radio" name="q02" :value="1" v-model="question[1]" id="selfcheck__q02--yes">
						<label for="selfcheck__q02--yes"><img src="~@/assets/img/shindan_ok.svg" alt="YES"></label>
						<input type="radio" name="q02" :value="0" v-model="question[1]" id="selfcheck__q02--no">
						<label for="selfcheck__q02--no"><img src="~@/assets/img/shindan_ng.svg" alt="NO"></label>
					</div>
				</li>
				<li class="selfcheck__questions--item">
					<span class="selfcheck__questions--heading">Q.3</span>
					<p class="selfcheck__questions--text">周りの人が暑いのに、自分だけ寒い。長袖が常に手放せない。</p>
					<div class="selfcheck__questions--label">
						<input type="radio" name="q03" :value="1" v-model="question[2]" id="selfcheck__q03--yes">
						<label for="selfcheck__q03--yes"><img src="~@/assets/img/shindan_ok.svg" alt="YES"></label>
						<input type="radio" name="q03" :value="0" v-model="question[2]" id="selfcheck__q03--no">
						<label for="selfcheck__q03--no"><img src="~@/assets/img/shindan_ng.svg" alt="NO"></label>
					</div>
				</li>
				<li class="selfcheck__questions--item">
					<span class="selfcheck__questions--heading">Q.4</span>
					<p class="selfcheck__questions--text">顔がほてりやすい、<br>全身がほてりやすい</p>
					<div class="selfcheck__questions--label">
						<input type="radio" name="q04" :value="1" v-model="question[3]" id="selfcheck__q04--yes">
						<label for="selfcheck__q04--yes"><img src="~@/assets/img/shindan_ok.svg" alt="YES"></label>
						<input type="radio" name="q04" :value="0" v-model="question[3]" id="selfcheck__q04--no">
						<label for="selfcheck__q04--no"><img src="~@/assets/img/shindan_ng.svg" alt="NO"></label>
					</div>
				</li>
				<li class="selfcheck__questions--item">
					<span class="selfcheck__questions--heading">Q.5</span>
					<p class="selfcheck__questions--text">温度差が強いと、倦怠感、<br>冷え症、頭痛、肩こりなどの<br>様々な症状がでる</p>
					<div class="selfcheck__questions--label">
						<input type="radio" name="q05" :value="2" v-model="question[4]" id="selfcheck__q05--yes">
						<label for="selfcheck__q05--yes"><img src="~@/assets/img/shindan_ok.svg" alt="YES"></label>
						<input type="radio" name="q05" :value="0" v-model="question[4]" id="selfcheck__q05--no">
						<label for="selfcheck__q05--no"><img src="~@/assets/img/shindan_ng.svg" alt="NO"></label>
					</div>
				</li>
				<li class="selfcheck__questions--item">
					<span class="selfcheck__questions--heading">Q.6</span>
					<p class="selfcheck__questions--text">熱中症、または近い状態に<br>なったことがある</p>
					<div class="selfcheck__questions--label">
						<input type="radio" name="q06" :value="1" v-model="question[5]" id="selfcheck__q06--yes">
						<label for="selfcheck__q06--yes"><img src="~@/assets/img/shindan_ok.svg" alt="YES"></label>
						<input type="radio" name="q06" :value="0" v-model="question[5]" id="selfcheck__q06--no">
						<label for="selfcheck__q06--no"><img src="~@/assets/img/shindan_ng.svg" alt="NO"></label>
					</div>
				</li>
				<li class="selfcheck__questions--item">
					<span class="selfcheck__questions--heading">Q.7</span>
					<p class="selfcheck__questions--text">季節の変わり目に、<br>体調不良になる</p>
					<div class="selfcheck__questions--label">
						<input type="radio" name="q07" :value="2" v-model="question[6]" id="selfcheck__q07--yes">
						<label for="selfcheck__q07--yes"><img src="~@/assets/img/shindan_ok.svg" alt="YES"></label>
						<input type="radio" name="q07" :value="0" v-model="question[6]" id="selfcheck__q07--no">
						<label for="selfcheck__q07--no"><img src="~@/assets/img/shindan_ng.svg" alt="NO"></label>
					</div>
				</li>
				<li class="selfcheck__questions--item">
					<span class="selfcheck__questions--heading">Q.8</span>
					<p class="selfcheck__questions--text">冷え症がある</p>
					<div class="selfcheck__questions--label">
						<input type="radio" name="q08" :value="1" v-model="question[7]" id="selfcheck__q08--yes">
						<label for="selfcheck__q08--yes"><img src="~@/assets/img/shindan_ok.svg" alt="YES"></label>
						<input type="radio" name="q08" :value="0" v-model="question[7]" id="selfcheck__q08--no">
						<label for="selfcheck__q08--no"><img src="~@/assets/img/shindan_ng.svg" alt="NO"></label>
					</div>
				</li>
				<li class="selfcheck__questions--item">
					<span class="selfcheck__questions--heading">Q.9</span>
					<p class="selfcheck__questions--text">温度が一定の環境にいる時間が長い（室内では一日中エアコンをつけている）</p>
					<div class="selfcheck__questions--label">
						<input type="radio" name="q09" :value="1" v-model="question[8]" id="selfcheck__q09--yes">
						<label for="selfcheck__q09--yes"><img src="~@/assets/img/shindan_ok.svg" alt="YES"></label>
						<input type="radio" name="q09" :value="0" v-model="question[8]" id="selfcheck__q09--no">
						<label for="selfcheck__q09--no"><img src="~@/assets/img/shindan_ng.svg" alt="NO"></label>
					</div>
				</li>
				<li class="selfcheck__questions--item">
					<span class="selfcheck__questions--heading">Q.10</span>
					<p class="selfcheck__questions--text">代謝が悪い、体がむくみやすい</p>
					<div class="selfcheck__questions--label">
						<input type="radio" name="q10" :value="1" v-model="question[9]" id="selfcheck__q10--yes">
						<label for="selfcheck__q10--yes"><img src="~@/assets/img/shindan_ok.svg" alt="YES"></label>
						<input type="radio" name="q10" :value="0" v-model="question[9]" id="selfcheck__q10--no">
						<label for="selfcheck__q10--no"><img src="~@/assets/img/shindan_ng.svg" alt="NO"></label>
					</div>
				</li>
			</ul>
		</section>
		<!-- /寒暖差疲労セルフチェック -->
		<!-- NOTE:ここまで公式WEBに流用 -->

		<!-- 結果をみる -->
		<a @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=selfcheckTemperatureDifferenceRes`, _result: String(computedRequltParam), _answer: computedAnswerParam } })" id="trackingSelfcheckResultBtn01" :class="[
			'selfcheck__button',
			{ 'is-eabled': computedEabledflag }
		]">結果をみる</a>
		<!-- /結果をみる -->

		<section class="selfcheck__outro">
			<a @click="$router.push({ name: 'Cpsite', query: { id: sugotokuContentsId } })" class="about-rightnow__login--button">TOPにもどる</a>
		</section>
	</article>
</div>
</template>

<script>
import cmnConst from '@/assets/js/constant.js'

export default {
	name: 'SelfcheckTemperatureDifference',
	components: {
	},
	data () {
		return {
			sugotokuContentsId: cmnConst.SUGOTOKU_CONTENTS_ID,
			backendUrl: cmnConst.BACKEND_URL,
			question: []
		}
	},
	props: {
	},
	computed: {
		// 結果を算出する
		computedRequltParam () {
			const resultParam = this.question.reduce((accumulator, currentValue) => {
				return accumulator + currentValue
			}, 0)
			return resultParam >= 7 ? 2 : resultParam >= 4 ? 1 : 0
		},

		// どの設問を〇にしたかを算出する
		computedAnswerParam () {
			return this.question.map((value, index) => {
				return value ? index + 1 : 0
			}).filter(item => item !== 0).join(',')
		},

		// すべての設問を選択したかを算出する
		computedEabledflag () {
			const maxQuestion = 10
			return this.question.filter(item => typeof item !== 'undefined').length === maxQuestion
		}
	},
	mounted () {
		// コンテンツの高さをemitする処理
		// CHANGED: コンテンツの高さを確保する処理の見直しのためコメントアウト
		// this.$nextTick(() => {
		// 	setTimeout(() => {
		// 		const domContainer = document.getElementById('js_selfcheck_container')
		// 		if (domContainer) this.$emit('containerHeight', domContainer.clientHeight)
		// 	}, 1000)
		// })
	},
	beforeDestroy () {
	},
	methods: {
	}
}
</script>

<style lang="scss" scoped>
// NOTE: このページ限定の変数（デザインシステム外のため他での利用禁止）
// NOTE: 公式WEBに流用するためvariableのimportはしていません
$lp-family-secondary: 'M+ C Type-1 (basic latin) Medium', 'M+ Type-1 (general-j) Medium', sans-serif;
$lp-family-primary: 'M+ C Type-1 (basic latin) Regular', 'M+ Type-1 (general-j) Regular', sans-serif;

$spacing-2: 0.125rem;
$spacing-4: 0.25rem;
$spacing-6: 0.375rem;
$spacing-8: 0.5rem;
$spacing-10: 0.625rem;
$spacing-12: 0.75rem;
$spacing-13: 0.812rem;
$spacing-14: 0.875rem;
$spacing-15: 0.937rem;
$spacing-20: 1.25rem;
$spacing-22: 1.375rem;
$spacing-26: 1.625rem;
$spacing-28: 1.75rem;
$spacing-40: 2.5rem;
$spacing-50: 3.125rem;

$font-size-10: 0.625rem;
$font-size-13: 0.812rem;
$font-size-14: 0.875rem;
$font-size-15: 0.937rem;
$font-size-18: 1.125rem;
$font-size-20: 1.25rem;
$font-size-24: 1.5rem;
$font-size-28: 1.75rem;
$font-size-36: 2.25rem;

$text-seconday: #FFF;
$selfcheck-primary: #FF5400;
$selfcheck-secondary: #FC7053;
$selfcheck-button-disabled: #CCC;
$selfcheck-button-yes: #F48B56;
$selfcheck-button-no: #21498D;
$selfcheck-submit-background: #FF4D4D;
$selfcheck-submit-border: #B43333;
$selfcheck-submit-disabled: #CCC;
$selfcheck-submit-disabled-border: #999;

.selfcheck {
	padding: 0;
	max-width: 640px;
	margin: 0 auto;

	&__header {
		padding: $spacing-10 $spacing-14 0;
		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}

	&__intro {
		font-family: $lp-family-primary;
		margin: $spacing-20 0 0 0;
		padding: 0 $spacing-14;
		font-size: $font-size-15;

		span {
			font-size: $font-size-13;
		}
	}

	&__questions {
		width: 92.5334%;
		margin: $spacing-22 auto 0;

		&--list {
			margin: 0;
			padding: 0;
		}

		&--item {
			margin-top: $spacing-26;
			padding: $spacing-15 $spacing-20 $spacing-28;
			list-style: none;
			font-family: $lp-family-secondary;
			border: 2px solid $selfcheck-secondary;
			border-radius: 10px;

			&:first-of-type { margin: 0; }
		}

		&--heading {
			display: block;
			color: $selfcheck-primary;
			font-size: $font-size-36;
			font-weight: bold;
			text-align: center;
			border-bottom: 1px dashed $selfcheck-primary;
		}

		&--text {
			margin: $spacing-15 0 0;
			padding: 0 $spacing-8;
			text-align: center;
			font-size: $font-size-18;
			min-height: 3em;
		}

		&--label {
			margin: 0 auto;
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 92.41%;
			margin-top: $spacing-12;

			input[type="radio"] {
				display: none;

				& + label {
					border-radius: 6px;
					width: 46.43%;
					height: 40px;
					display: flex;
					justify-content: center;
					align-items: center;
					color: $text-seconday;
					font-weight: bold;
					background-color: $selfcheck-button-disabled;
				}

				&:nth-of-type(odd) {
					& + label {
						font-size: $font-size-24;
					}

					&:checked + label {
						background-color: $selfcheck-button-yes;
					}
				}

				&:nth-of-type(even) {
					& + label {
						font-size: $font-size-28;
					}

					&:checked + label {
						background-color: $selfcheck-button-no;
					}
				}
			}
		}
	}

	&__button {
		display: block;
		width: 80%;
		margin: $spacing-50 auto 0;
		padding: $spacing-13 0;
		box-sizing: border-box;
		background: $selfcheck-submit-disabled;
		border-radius: 6px;
		text-align: center;
		color: $text-seconday;
		font-weight: bold;
		font-size: $font-size-24;
		box-shadow: 0 3px $selfcheck-submit-disabled-border;
		pointer-events: none;

		&.is-eabled {
			background: $selfcheck-submit-background;
			box-shadow: 0 3px $selfcheck-submit-border;
			pointer-events: auto;
		}
	}

	&__outro {
		margin-top: $spacing-40;
	}
}
</style>
